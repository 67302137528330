import styled from 'styled-components';

import TopSectionImage from '../../../Assets/IMG/VacuumStabilizer.jpg';

const TopSectionImageStyled = styled.section`
	height: 495px;
	background: url(${TopSectionImage}) no-repeat 0px -300px;
	border-radius: 24px;
	padding: 60px 90px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media (max-width: 769px) {
		border-radius: 0px;
	}

	@media (max-width: 1000px) {
		padding: 40px 60px;
	}

	@media (max-width: 800px) {
		padding: 20px 40px;
	}

	@media (max-width: 600px) {
		padding: 20px;
	}

	@media (max-width: 1200px) {
		background: url(${TopSectionImage}) no-repeat -100px -300px;
	}

	@media (max-width: 1050px) {
		background: url(${TopSectionImage}) no-repeat -150px -300px;
	}

	@media (max-width: 1000px) {
		background: url(${TopSectionImage}) no-repeat -250px -300px;
	}

	@media (max-width: 800px) {
		background: url(${TopSectionImage}) no-repeat center -30px/ 160%;
	}

	@media (max-width: 700px) {
		background: url(${TopSectionImage}) no-repeat center 0/ 180%;
	}

	@media (max-width: 600px) {
		background: url(${TopSectionImage}) no-repeat center 0px/ 210%;
	}

	@media (max-width: 550px) {
		background: url(${TopSectionImage}) no-repeat center 0px/ 250%;
	}

	@media (max-width: 500px) {
		background: url(${TopSectionImage}) no-repeat center 0px/ 280%;
	}

	@media (max-width: 450px) {
		background: url(${TopSectionImage}) no-repeat center -30px/ 350%;
	}

	@media (max-width: 400px) {
		background: url(${TopSectionImage}) no-repeat center -70px/ 400%;
	}

	@media (max-width: 320px) {
		background: url(${TopSectionImage}) no-repeat center 0px/ 400%;
	}

	.section__description {
		color: #fff;
	}

	.section__logo {
		@media (max-width: 450px) {
			display: none;
		}
	}

	.description__heading {
		font-family: 'Intro Bold Regular';
		font-size: 40px;
		line-height: 45px;

		@media (max-width: 800px) {
			font-size: 34px;
			text-align: center;
		}

		@media (max-width: 600px) {
			font-size: 30px;
			text-align: center;
		}

		@media (max-width: 375px) {
			font-size: 28px;
			text-align: center;
		}
	}

	.description__paragraph {
		font-size: 26px;
		line-height: 25px;
		margin-top: 20px;

		@media (max-width: 800px) {
			font-size: 22px;
			text-align: center;
		}

		@media (max-width: 600px) {
			font-size: 18px;
			text-align: center;
		}
	}
`;

export { TopSectionImageStyled };
