import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ModalContextProvider } from './store/ModalContextProvider';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<ModalContextProvider>
		<App />
	</ModalContextProvider>
);
