import React, { useState } from 'react';

export const ModalContext = React.createContext({
	isVisible: false,
	setIsVisible: () => {},
});

type Props = {
	children: JSX.Element;
};

export const ModalContextProvider = (props: Props) => {
	const [isVisible, setIsVisible] = useState(false);

	const visibilityHandler = () => {
		setIsVisible(!isVisible);
	};
	return (
		<ModalContext.Provider
			value={{ isVisible, setIsVisible: visibilityHandler }}
		>
			{props.children}
		</ModalContext.Provider>
	);
};
