import styled from 'styled-components';

const AreasOfMedicineSection = styled.section`
	margin: 80px 0 50px;

	.section__content {
		margin: 70px 0 90px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;

		@media (max-width: 1150px) {
			justify-content: center;
		}
	}

	.content__list {
		font-size: 20px;
		align-self: flex-start;
		margin-bottom: 30px;

		@media (max-width: 1150px) {
			padding: 0 20px;
		}

		@media (max-width: 970px) {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}

	.block__text {
		font-family: 'Intro Bold Regular';
		@media (max-width: 375px) {
			font-size: 18px;
		}
		@media (max-width: 320px) {
			font-size: 16px;
		}
	}

	.block__icon {
		padding-right: 12px;
	}

	.upper {
		margin-bottom: 30px;
	}
`;

export { AreasOfMedicineSection };
