import styled from 'styled-components';

const ButtonStyled = styled.button`
	border: none;
	font-family: inherit;
	color: #fff;
	background: #009d9b;
	padding: 8px 37px;
	font-size: inherit;
	border-radius: 100px;
	cursor: pointer;
	transition: 0.15s;

	:hover {
		background: #00aca9;
	}
`;

export { ButtonStyled };
