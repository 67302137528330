import styled from 'styled-components';

const SectionStyled = styled.section`
	padding: 50px 0;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;

	@media (max-width: 780px) {
		flex-direction: column;
	}

	.section__logo {
		@media (max-width: 780px) {
			margin-bottom: 30px;
		}
	}

	.section__contacts {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		@media (max-width: 600px) {
			flex-direction: column-reverse;
		}
	}

	.contacts__phone {
		font-family: 'Intro Bold Regular';
		margin-right: 35px;
		margin-left: 30px;
		@media (max-width: 600px) {
			margin: 0;
		}
	}

	.contacts__phone {
		margin-left: 0px;
		margin-right: 45px;
		@media (max-width: 780px) {
			margin-right: 30px;
		}
		@media (max-width: 600px) {
			margin: 25px 0;
		}
	}
`;

export { SectionStyled };
