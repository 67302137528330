import React from 'react';
import { LeaveRequestModal } from './LeaveRequestModal';

export const LeaveRequestModalContainer = () => {
	const submitFormData = async (
		enteredName: string,
		enteredEmail: string,
		enteredPhone: string
	) => {
		try {
			const response = await fetch(
				'https://formsubmit.co/ajax/info@npwt.ru',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Accept: 'application/json',
					},
					body: JSON.stringify({
						name: `${enteredName}`,
						email: `${enteredEmail}`,
						phone: `${enteredPhone}`,
					}),
				}
			);
			const data = await response.json();
			console.log(data);
		} catch (error) {
			console.log(error);
		}
	};

	return <LeaveRequestModal submitFormData={submitFormData} />;
};
