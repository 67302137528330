import React from 'react';

import HeaderStyled from './HeaderStyled';

import { HeaderItem } from './HeaderItem';
import { HeaderAdaptive } from './HeaderBurgerMenu/HeaderAdaptive';

const Header = (): JSX.Element => {
	return (
		<HeaderStyled>
			<div className="container">
				<HeaderAdaptive />
				<nav className="navbar">
					<ul className="navbar__list">
						<HeaderItem
							title="Вакуумный стабилизатор"
							href="#vacuum_stabilizer"
							offset={30}
						/>
						<HeaderItem
							title="документация"
							href="#documentation"
							offset={30}
						/>
						<HeaderItem
							title="Исследования"
							href="#researcher"
							offset={30}
						/>
						<HeaderItem
							title="О компании"
							href="#about_us"
							offset={30}
						/>
						<HeaderItem
							title="Контакты"
							href="#contacts"
							offset={0}
						/>
					</ul>
				</nav>
			</div>
		</HeaderStyled>
	);
};

export { Header };
