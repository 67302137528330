import styled from 'styled-components';

import img from '../../../Assets/IMG/Researches.jpg';

export const ResearchesStyled = styled.section`
	margin: 80px 0;
	background: url(${img}) no-repeat center center/cover;
	min-height: 520px;
	border-radius: 25px;
	display: flex;
	align-items: center;

	@media (max-width: 769px) {
		border-radius: 0px;
		background: radial-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
			url(${img}) no-repeat -30px center/cover;
		min-height: 420px;
	}
	.section__description {
		color: #fff;
		margin-left: 90px;
		max-width: 375px;
		@media (max-width: 1024px) {
			margin-left: 60px;
		}
		@media (max-width: 769px) {
			margin: auto;
			text-align: center;
			max-width: 475px;
			padding: 40px 30px;
		}
	}

	.description__heading {
		font-size: 40px;
		font-family: 'Intro Bold Regular';
		@media (max-width: 375px) {
			font-size: 30px;
		}
	}

	.description__paragrapg {
		margin-top: 40px;
		@media (max-width: 375px) {
			font-size: 16px;
		}
	}
`;
