import styled from 'styled-components';

import image from '../../../Assets/IMG/CallBack.png';

export const CallBackDiv = styled.div`
	background: url(${image}) no-repeat center 160px;
	max-width: 495px;
	width: 100%;
	height: 490px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #f6f6f8;
	z-index: 35;
	border-radius: 25px;

	@media (max-width: 495px) {
		border-radius: 0px;
	}

	.heading {
		text-align: center;
		margin-top: 40px;
		font-weight: normal;
		font-size: 25px;
	}

	.close-btn {
		border: none;
		background: none;
		outline: none;
	}

	.close-btn__icon {
		position: absolute;
		width: 35px;
		top: 2%;
		right: 2%;
		cursor: pointer;
	}
`;
