import styled from 'styled-components';

export const WhyWeStyled = styled.section`
	margin-top: 90px;

	.section__content {
		display: flex;
		flex-direction: column;
	}

	.content__achivments {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin-top: 60px;
	}

	.achivments__paragraph {
		margin: 0 35px 20px;
		max-width: 435px;
		text-align: justify;
		font-size: 20px;
		@media (max-width: 768px) {
			text-align: center;
			font-size: 18px;
		}
	}

	.content__facts {
		margin-top: 30px;
		background: #009d9b;
		font-size: 20px;
		padding: 60px;
		border-radius: 25px;
		text-align: center;
		@media (max-width: 768px) {
			border-radius: 0;
		}
	}

	.facts__list {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		flex-wrap: wrap;
		@media (max-width: 1119px) {
			justify-content: center;
		}
	}

	.list__item {
		max-width: 230px;
		padding: 0 30px;

		@media (max-width: 1119px) {
			margin-bottom: 35px;
		}
	}

	.item__paragrapg--white {
		color: #fff;
	}

	.item__line {
		border: 2px solid #fff;
		width: 70px;
		margin: 22px auto;
	}
`;
