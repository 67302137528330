import React from 'react';
import { HeadingStyled } from '../../../StyledComponents/Heading';
import { VacuumTherapyStyled } from './VacuumTherapyStyled';

import image from '../../../Assets/SVG/VacuumTherapy.svg';

export const VacuumTherapy = () => {
	return (
		<VacuumTherapyStyled>
			<HeadingStyled>вакуумная терапия ран</HeadingStyled>
			<div className="section__content">
				<div className="content__description">
					<p className="description__paragraph">
						Вакуумная (VAC) терапия – метод лечения «чистых» и
						гнойных ран, основанный на положительном эффекте
						создания отрицательного давления на раневом ложе.
						Лечение ран отрицательным давлением (Negative pressure
						wound treatment — NPWT) является инновационным методом
						лечения ран различной этиологии, ускоряющим течение
						раневого процесса.
					</p>
					<br />
					<p className="description__paragraph">
						В комплексном лечении инфицированных и гнойных, острых и
						хронических ран VAC-терапия способствует более быстрому
						купированию местного гнойно-воспалительного процесса,
						деконтаминации ран, очищению от некрозов, позволяет
						сократить сроки подготовки раны к пластическому
						закрытию, избежать ежедневных болезненных перевязок,
						предотвратить развитие внутрибольничной инфекции.
					</p>
				</div>
				<div className="content__image-block">
					<img className="image-block__img" src={image} alt="" />
				</div>
			</div>
		</VacuumTherapyStyled>
	);
};
