import React from 'react';

import { vastaProvidesData } from './VastaProvidesData';

import { HeadingStyled } from '../../../StyledComponents/Heading';
import { VastaProvidesStyled } from './VastaProvidesStyled';

import { VastaProvidesItem } from './VastaProvidesItem';

const items = vastaProvidesData.map((item) => (
	<VastaProvidesItem
		key={item.id}
		logo={item.logo}
		description={item.description}
	/>
));

const VastaProvides = () => {
	return (
		<VastaProvidesStyled>
			<HeadingStyled>Васта обеспечивает</HeadingStyled>
			<div className="section__list">{items}</div>
		</VastaProvidesStyled>
	);
};

export { VastaProvides };
