import React from 'react';
import { Backdrop, Window } from './ModalWindowStyled';

import closeIcon from '../../../Assets/SVG/CloseIcon.svg';

interface ModalWindwProps {
	onClose: () => void;
	heading: string;
	children: JSX.Element;
}

export const ModalWindowEmpty = (props: ModalWindwProps) => {
	const { onClose, heading } = props;
	return (
		<>
			<Backdrop onClick={onClose}></Backdrop>
			<Window
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 0.3 }}
			>
				<button onClick={onClose} className="close-btn">
					<img className="close-btn__icon" src={closeIcon} alt="" />
				</button>

				<h2 className="heading">{heading}</h2>
				{props.children}
			</Window>
		</>
	);
};
