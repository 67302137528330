import styled from 'styled-components';

const VastaProvidesStyled = styled.section`
	margin-top: 85px;

	.section__list {
		margin-top: 50px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}

	.list__item {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: baseline;
		text-align: center;
		width: 228px;
		margin-bottom: 45px;
		padding: 0 20px;
	}

	.item__logo {
		height: 90px;
		width: 90px;
	}

	.item__description {
		font-size: 20px;
		font-family: 'Intro Bold Regular';
		margin-top: 20px;
		@media (max-width: 768px) {
			font-size: 18px;
		}
	}
`;

export { VastaProvidesStyled };
