import React from 'react';
import { AreasOfMedicine } from './AreasOfMedicine/AreasOfMedicine';
import { Consumables } from './Consumables/Consumables';
import { ContactInfo } from './ContactInfo/ContactInfo';
import { Documentation } from './Documentation/Documentation';
import { Researches } from './Researches/Researches';

import { TopSectionImage } from './TopSectionImage/TopSectionImage';
import { TrustedParthner } from './TrustedParthner/TrustedParthner';
import { VacuumStabilizer } from './VacuumStabilizer/VacuumStabilizer';
import { VacuumTherapy } from './VacuumTherapy/VacuumTherapy';
import { VastaProvides } from './VastaProvides/VastaProvides';
import { WhyWe } from './WhyWe/WhyWe';

const Main = () => {
	return (
		<main className="container">
			<ContactInfo />
			<TopSectionImage />
			<AreasOfMedicine />
			<VacuumStabilizer />
			<VastaProvides />
			<Consumables />
			<Documentation />
			<Researches />
			<VacuumTherapy />
			<WhyWe />
			<TrustedParthner />
		</main>
	);
};

export { Main };
