import styled from 'styled-components';

export const ConsumablesStyled = styled.section`
	margin-top: 35px;

	.section__list {
		margin-top: 40px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}

	.list_item {
		max-width: 260px;
		margin: 0 12px;
		margin-bottom: 50px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.item__image {
		width: 260px;
		height: 260px;
		@media (max-width: 375px) {
			width: 220px;
			height: 220px;
		}
	}

	.item__description {
		margin-top: 20px;
	}

	.description__heading {
		font-family: 'Intro Bold Regular';
		font-size: 20px;
		@media (max-width: 567px) {
			text-align: center;
		}
		@media (max-width: 320px) {
			font-size: 18px;
		}
	}

	.description_paragraph {
		margin-top: 20px;
		white-space: pre-wrap;
		@media (max-width: 567px) {
			text-align: center;
		}
		@media (max-width: 320px) {
			font-size: 15px;
		}
	}
`;
