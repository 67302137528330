import styled, { createGlobalStyle } from 'styled-components';

const HeaderAdaptiveStyled = styled.div`
	display: none;
	min-height: 60px;
	position: relative;
	@media (max-width: 950px) {
		display: flex;
		align-items: center;
	}

	.burger-menu {
		width: 35px;
		height: 35px;
		z-index: 9;
		@media (max-width: 425px) {
			width: 30px;
			height: 30px;
		}
	}

	.burger-menu__icon {
		width: 100%;
	}

	.navbar {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		background: #009d9b;
		z-index: 1;
	}

	.navbar__list {
		height: 100%;
		display: block;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: flex-start;
		font-size: 22px;
		margin-top: 80px;
	}

	.list__item {
		margin-bottom: 50px;
		font-size: 24px;
		@media (max-width: 768px) {
			font-size: 22px;
		}
		@media (max-width: 425px) {
			font-size: 20px;
			margin-bottom: 40px;
		}
		@media (max-width: 375px) {
			font-size: 18px;
			margin-bottom: 35px;
		}
		@media (max-width: 280px) {
			font-size: 16px;
			margin-bottom: 30px;
		}
	}

	.item__link {
		color: #fff;
	}
`;

const GlobalStyle = createGlobalStyle<{ toggleIcon: boolean }>`
	body {
    overflow-y: ${(props) => (!props.toggleIcon ? 'hidden' : 'auto')};
  }
`;

export { HeaderAdaptiveStyled, GlobalStyle };
