import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Window = styled(motion.div)`
	max-width: 495px;
	width: 100%;
	height: 490px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #f6f6f8;
	z-index: 30;
	border-radius: 25px;
	display: flex;
	flex-direction: column;
	padding: 40px;
	@media (max-width: 495px) {
		border-radius: 0px;
		padding: 40px 20px;
	}

	@media (max-width: 320px) {
		border-radius: 0px;
		padding: 40px 20px;
	}

	.close-btn {
		border: none;
		background: none;
		outline: none;
	}

	.close-btn__icon {
		position: absolute;
		width: 35px;
		top: 2%;
		right: 2%;
		cursor: pointer;
	}

	.heading {
		text-align: center;
		margin-top: 20px;
		font-weight: normal;
		font-size: 25px;
	}
`;

export const Backdrop = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	background: rgba(0, 0, 0, 0.75);
`;
