import logo1 from '../../../Assets/SVG/Icon1.svg';
import logo2 from '../../../Assets/SVG/Icon2.svg';
import logo3 from '../../../Assets/SVG/Icon3.svg';
import logo4 from '../../../Assets/SVG/Icon4.svg';
import logo5 from '../../../Assets/SVG/Icon5.svg';
import logo6 from '../../../Assets/SVG/Icon6.svg';
import logo7 from '../../../Assets/SVG/Icon7.svg';
import logo8 from '../../../Assets/SVG/Icon8.svg';
import logo9 from '../../../Assets/SVG/Icon9.svg';
import logo10 from '../../../Assets/SVG/Icon10.svg';
import logo11 from '../../../Assets/SVG/Icon11.svg';
import logo12 from '../../../Assets/SVG/Icon12.svg';
import logo13 from '../../../Assets/SVG/Icon13.svg';

export const vastaProvidesData = [
	{
		id: 'vp1',
		logo: logo1,
		description: 'Возможность применения в амбулаторном режиме',
	},
	{
		id: 'vp2',
		logo: logo2,
		description: 'Сокращение стационарного «койко-дня» в 3-7 раза',
	},
	{
		id: 'vp3',
		logo: logo3,
		description: 'Заживляемость сложных ран в 2 раза быстрее',
	},
	{
		id: 'vp4',
		logo: logo4,
		description: 'Снижение частоты перевязок',
	},
	{
		id: 'vp5',
		logo: logo5,
		description: 'Предотвращение осложнений раны',
	},
	{
		id: 'vp6',
		logo: logo6,
		description: 'Содействие быстрому сокращению объема раны',
	},
	{
		id: 'vp7',
		logo: logo7,
		description: 'Улучшение качества жизни',
	},
	{
		id: 'vp8',
		logo: logo8,
		description:
			'Миграцию клеток и формирование новых тканей в раневом ложе',
	},
	{
		id: 'vp9',
		logo: logo9,
		description: 'Формирование новых капилляров',
	},
	{
		id: 'vp10',
		logo: logo10,
		description: 'Концентрацию факторов роста и их распространение',
	},
	{
		id: 'vp11',
		logo: logo11,
		description: 'Устранение локального отека',
	},
	{
		id: 'vp12',
		logo: logo12,
		description: 'Устранение бактериального обилия',
	},
	{
		id: 'vp13',
		logo: logo13,
		description: 'Снижение инфекции',
	},
];
