import React, { useContext } from 'react';
import { ButtonLightBlue } from '../UI/ButtonLightBlue';
import { ButtonWhite } from '../../StyledComponents/ButtonWhite';
import { FooterStyled } from './FooterStyled';

import logo from '../../Assets/SVG/MedikaFooterLogo.svg';
import { ModalContext } from '../../store/ModalContextProvider';

import Presentation from '../../Assets/Documents/Presentation.pdf';

export const Footer = () => {
	const modalCtx = useContext(ModalContext);
	return (
		<FooterStyled id="contacts">
			<div className="container">
				<div className="wrapper">
					<div className="wrapper__content">
						<div className="content__item">
							<div className="item__logo">
								<img src={logo} alt="" />
							</div>
							<p>
								2011 © НПП Медика. <br /> Все права защищены
							</p>
						</div>
						<div className="content__item">
							<div className="item__info">
								<span className="info__text">Адрес:</span>
								<p>
									111024, г. Москва, Перовский проезд, дом 35,
									строение 3, этаж 4, помещение I, ком. № 5.
								</p>
							</div>
							<div className="item__info">
								<span className="info__text">E-mail:</span>
								<p>info@npwt.ru</p>
							</div>
						</div>
						<div className="content__item">
							<div className="item__info">
								<span className="info__text">
									Телефон/факс:
								</span>
								<p>+7 (495) 363-34-54</p>
							</div>
						</div>
						<div className="content__item privacy_policy">
							<div className="item__actions">
								<ButtonWhite
									onClick={modalCtx.setIsVisible}
									className="actions__btn"
								>
									Связаться
								</ButtonWhite>
								<a href={Presentation} download={Presentation}>
									<ButtonLightBlue>
										Скачать презентацию
									</ButtonLightBlue>
								</a>
							</div>
							<p className="policy">
								Политика конфиденциальности и обработка
								персональных данных
							</p>
						</div>
					</div>
				</div>
			</div>
		</FooterStyled>
	);
};
