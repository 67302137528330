import React from 'react';
import { NameInput } from './Inputs/NameInput';
import { EmailInput } from './Inputs/EmailInput';
import { PhoneInput } from './Inputs/PhoneInput';
import { ButtonStyled } from '../../../StyledComponents/Button';

import { FormProps } from './FormInterface';
import { FormStyled } from './FormStyled';

export const Form = (props: FormProps) => {
	const {
		nameInputChangeHandler,
		nameBlurChangeHandler,
		nameHasError,
		enteredName,
		nameInputClasses,
	} = props.nameProps;

	const {
		emailInputChangeHandler,
		emailBlurChangeHandler,
		emailHasError,
		enteredEmail,
		emailInputClasses,
	} = props.emailProps;

	const {
		phoneInputChangeHandler,
		phoneBlurChangeHandler,
		phoneHasError,
		enteredPhone,
		phoneInputClasses,
	} = props.phoneProps;

	const { onSubmit, btnClassName, formIsValid } = props;

	return (
		<FormStyled
			onSubmit={onSubmit}
			className="form"
			action={`${process.env.FORM_SUBMIT}`}
			method="POST"
		>
			<div className="form__elements">
				<NameInput
					onChange={nameInputChangeHandler}
					onBlur={nameBlurChangeHandler}
					hasError={nameHasError}
					className={nameInputClasses}
					value={enteredName}
				/>

				<EmailInput
					onChange={emailInputChangeHandler}
					onBlur={emailBlurChangeHandler}
					hasError={emailHasError}
					className={emailInputClasses}
					value={enteredEmail}
				/>

				<PhoneInput
					className={phoneInputClasses}
					value={enteredPhone}
					onBlur={phoneBlurChangeHandler}
					onChange={phoneInputChangeHandler}
					hasError={phoneHasError}
				></PhoneInput>
			</div>
			<ButtonStyled
				type="submit"
				disabled={!formIsValid}
				className={`form__btn + ${btnClassName}`}
			>
				Отправить
			</ButtonStyled>
		</FormStyled>
	);
};
