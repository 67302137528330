import { useState } from 'react';

export const useInput = (validateValue: any) => {
	const [enteredValue, setEnteredValue] = useState('');
	const [enteredValueIsTouched, setEnteredValueIsTouched] = useState(false);

	const isValidValue = validateValue(enteredValue);
	const valueHasError = !isValidValue && enteredValueIsTouched;

	const valueInputChangeHandler = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		setEnteredValue(e.target.value);
	};

	const valueBlurChangeHandler = () => {
		setEnteredValueIsTouched(true);
	};

	const resetValue = () => {
		setEnteredValue('');
		setEnteredValueIsTouched(false);
	};

	return {
		enteredValue,
		enteredValueIsValid: isValidValue,
		valueHasError,
		valueInputChangeHandler,
		valueBlurChangeHandler,
		setEnteredValueIsTouched,
		resetValue,
	};
};
