import React from 'react';

import InputMask from 'react-input-mask';

import { InputProps } from '../FormInterface';
import { InputElement } from './InputStyled';

export const PhoneInput = (props: InputProps) => {
	const { value, onChange, className, onBlur, hasError } = props;
	return (
		<InputElement className="elements__item">
			<InputMask
				placeholder="Ваш телефон"
				name="phone"
				mask="+7-(999)-999-99-99"
				type="tel"
				value={value}
				onChange={onChange}
				className={className}
				onBlur={onBlur}
			></InputMask>
			{hasError && (
				<p className="item__error">
					Пожалуйста введите полный номер телефона
				</p>
			)}
		</InputElement>
	);
};
