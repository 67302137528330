import React from 'react';

import { VastaProvidesItemProps } from './VastaProvidesInterface';

export const VastaProvidesItem = (props: VastaProvidesItemProps) => {
	const { logo, description } = props;
	return (
		<div className="list__item">
			<img className="item__logo" src={logo} alt="" />
			<p className="item__description">{description}</p>
		</div>
	);
};
