import React, { useContext, useState, useEffect } from 'react';

import ReactDOM from 'react-dom';

import { ModalContext } from '../../store/ModalContextProvider';

import { useInput } from '../../Hooks/useInput';

import { CallBack } from '../UI/CallBackWindow/CallBack';

import { Form } from './Form/Form';
import { ModalWindowEmpty } from '../UI/ModalWindow/ModalWindow';

import { LeaveRequestModalProps } from './LeaveRequestModalInterface';

export const LeaveRequestModal = (props: LeaveRequestModalProps) => {
	const modalCtx = useContext(ModalContext);
	const [formIsValid, setFormIsValid] = useState(false);
	const [showCallBack, setShowCallBack] = useState(false);

	const {
		enteredValue: enteredName,
		enteredValueIsValid: isNameValid,
		valueHasError: nameHasError,
		valueBlurChangeHandler: nameBlurChangeHandler,
		valueInputChangeHandler: nameInputChangeHandler,
		setEnteredValueIsTouched: setNameIsTouched,
		resetValue: resetName,
	} = useInput((value: string) => value.trim() !== '');

	const validRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

	const {
		enteredValue: enteredEmail,
		enteredValueIsValid: isEmailValid,
		valueHasError: emailHasError,
		valueBlurChangeHandler: emailBlurChangeHandler,
		valueInputChangeHandler: emailInputChangeHandler,
		setEnteredValueIsTouched: setEmailIsTouched,
		resetValue: resetEmail,
	} = useInput((value: string) => value.match(validRegex));

	const {
		enteredValue: enteredPhone,
		valueHasError: phoneHasError,
		valueBlurChangeHandler: phoneBlurChangeHandler,
		valueInputChangeHandler: phoneInputChangeHandler,
		setEnteredValueIsTouched: setPhoneIsTouched,
		resetValue: resetPhone,
	} = useInput((value: string) => !value.includes('_'));

	useEffect(() => {
		if (
			isNameValid &&
			isEmailValid &&
			enteredPhone.trim() !== '' &&
			!enteredPhone.includes('_')
		) {
			setFormIsValid(true);
		} else {
			setFormIsValid(false);
		}
	}, [isNameValid, isEmailValid, enteredPhone]);

	const onSubmitHandler = (e: React.FormEvent) => {
		e.preventDefault();

		if (!formIsValid) {
			return;
		}

		props.submitFormData(enteredName, enteredEmail, enteredPhone);

		setNameIsTouched(false);
		setEmailIsTouched(false);
		setPhoneIsTouched(false);
		resetName();
		resetEmail();
		resetPhone();

		setShowCallBack(true);
	};

	const onCloseHandler = () => {
		modalCtx.setIsVisible();
		setNameIsTouched(false);
		setEmailIsTouched(false);
		setPhoneIsTouched(false);
		resetName();
		resetEmail();
		resetPhone();
		setShowCallBack(false);
	};

	const nameInputClasses = nameHasError ? 'invalid' : '';
	const emailInputClasses = emailHasError ? 'invalid' : '';
	const phoneInputClasses = phoneHasError ? 'invalid' : '';
	const submitBtnClasses = formIsValid ? '' : 'btn-invalid';

	return (
		<>
			{ReactDOM.createPortal(
				<>
					{modalCtx.isVisible && (
						<>
							{showCallBack && (
								<CallBack onClose={onCloseHandler} />
							)}
							<ModalWindowEmpty
								onClose={onCloseHandler}
								heading="Оставить Заявку"
							>
								<Form
									onSubmit={onSubmitHandler}
									formIsValid={formIsValid}
									btnClassName={`form__btn + ${submitBtnClasses}`}
									nameProps={{
										nameInputChangeHandler,
										nameBlurChangeHandler,
										nameInputClasses: `item__input + ${nameInputClasses}`,
										nameHasError,
										enteredName,
									}}
									emailProps={{
										emailInputChangeHandler,
										emailBlurChangeHandler,
										emailInputClasses: `item__input + ${emailInputClasses}`,
										emailHasError,
										enteredEmail,
									}}
									phoneProps={{
										phoneInputChangeHandler,
										phoneBlurChangeHandler,
										phoneInputClasses: `item__input + ${phoneInputClasses}`,
										phoneHasError,
										enteredPhone,
									}}
								/>
							</ModalWindowEmpty>
						</>
					)}
				</>,
				document.getElementById('modal-root') as HTMLElement
			)}
		</>
	);
};
