import React from 'react';
import { ButtonStyled } from '../../../StyledComponents/Button';
import { HeadingStyled } from '../../../StyledComponents/Heading';
import { DocumentationStyled } from './DocumentationStyled';

import DownloadIcon from '../../../Assets/SVG/DownloadIcon.svg';

import Presentation from '../../../Assets/Documents/Presentation.pdf';
import RuVasta from '../../../Assets/Documents/RuVasta.pdf';

export const Documentation = () => {
	return (
		<DocumentationStyled id="documentation">
			<HeadingStyled>Документация</HeadingStyled>
			<div className="section__actions">
				<a className="actions__link" href={RuVasta} download={RuVasta}>
					<ButtonStyled className="actions__btn">
						<img className="btn__icon" src={DownloadIcon} alt="" />
						<span>Скачать РУ РЗН</span>
					</ButtonStyled>
				</a>
				<div className="actions__line"></div>
				<a
					className="actions__link"
					href={Presentation}
					download={Presentation}
				>
					<ButtonStyled className="actions__btn">
						<img className="btn__icon" src={DownloadIcon} alt="" />
						<span>Скачать презентацию</span>
					</ButtonStyled>
				</a>
			</div>
		</DocumentationStyled>
	);
};
