import styled from 'styled-components';

const HeaderStyled = styled.header`
	color: #fff;
	height: 60px;
	background-color: #009d9b;

	@media (max-width: 769px) {
		padding: 0 20px;
	}
	@media (max-width: 375px) {
		padding: 0 15px;
	}

	.navbar__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		height: 60px;

		@media (max-width: 1150px) {
			font-size: 14px;
		}
		@media (max-width: 950px) {
			display: none;
		}
	}

	.list-item__link {
		color: #fff;
		text-transform: uppercase;
		display: flex;
		align-items: center;
	}

	.link__icon {
		margin-left: 10px;
		transform: translateY(0);
		transition: 0.2s;

		@media (max-width: 1150px) {
			transform: scale(0.9);
			margin-left: 7px;
		}
	}

	.navbar__list-item:hover .link__icon {
		transform: translateY(2px);
	}
`;

export default HeaderStyled;
