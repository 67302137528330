import styled from 'styled-components';

export const ButtonWhite = styled.button`
	font-family: inherit;
	width: 250px;
	border: none;
	color: #009d9b;
	background: #fff;
	font-size: 16px;
	padding: 7px 0;
	border-radius: 25px;
	cursor: pointer;
`;
