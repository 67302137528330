import React from 'react';

import logo from '../../../Assets/SVG/MedikaLogo.svg';

import { TopSectionImageStyled } from './TopSectionImageStyled';

const TopSectionImage = () => {
	return (
		<TopSectionImageStyled>
			<div className="section__description">
				<h2 className="description__heading">
					Вакуумный стабилизатор <br /> ВаСта
				</h2>
				<p className="description__paragraph">
					Аппарат для лечения ран <br /> отрицательным давлением
				</p>
			</div>
			<div className="section__logo">
				<img src={logo} alt="alt" />
			</div>
		</TopSectionImageStyled>
	);
};

export { TopSectionImage };
