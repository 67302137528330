import styled from 'styled-components';

const VacuumStabilizerSection = styled.section`
	.section__description {
		display: flex;
		align-items: center;
		font-size: 17px;
		margin-top: 45px;

		@media (max-width: 1350px) {
			flex-wrap: wrap;
			flex-direction: column;
			justify-content: center;
		}
	}

	.description__image {
		max-width: 550px;
		padding: 0 50px;
	}

	.image {
		border-radius: 25px;
		width: 100%;
	}

	.description__details {
		margin-left: 65px;

		@media (max-width: 1400px) {
			margin-left: 20px;
		}

		@media (max-width: 1350px) {
			margin-left: 0;
			margin-top: 20px;
			padding: 0 20px;
		}
	}

	.details__heading {
		font-size: 20px;
		margin-bottom: 50px;
		@media (max-width: 1350px) {
			text-align: center;
			margin-bottom: 40px;
		}
	}

	.details__characteristics {
		display: flex;
		justify-content: space-between;
	}

	.list__item {
		margin-bottom: 15px;
		display: flex;
		align-items: center;
		@media (max-width: 768px) {
			text-align: center;
			flex-direction: column;
		}
	}

	.item__characteristic {
		margin-left: 5px;
		font-family: 'Intro Bold Regular';
	}

	.names__list {
		@media (max-width: 1350px) {
			display: flex;
			align-items: center;
			flex-direction: column;
		}
	}
`;

export { VacuumStabilizerSection };
