import React from 'react';
import { HeadingStyled } from '../../../StyledComponents/Heading';
import { ConsumablesItem } from './ConsumablesItem';
import { ConsumablesStyled } from './ConsumablesStyled';

import { ConsumablesData } from './ConsumablesData';

const items = ConsumablesData.map((item) => (
	<ConsumablesItem
		key={item.id}
		image={item.image}
		title={item.title}
		description={item.description}
	/>
));

export const Consumables = () => {
	return (
		<ConsumablesStyled>
			<HeadingStyled>Расходные материалы</HeadingStyled>
			<div className="section__list">{items}</div>
		</ConsumablesStyled>
	);
};
