import React, { useContext } from 'react';

import { SectionStyled } from './ContactInfoStyled';
import { ButtonStyled } from '../../../StyledComponents/Button';

import MainLogo from '../../../Assets/SVG/MedikaHeaderLogo.svg';

import { ModalContext } from '../../../store/ModalContextProvider';

const ContactInfo = () => {
	const modalCtx = useContext(ModalContext);

	return (
		<SectionStyled>
			<img className="section__logo" src={MainLogo} alt="" />
			<div className="section__contacts">
				<span className="contacts__phone">+7 (495) 363-34-54</span>
				<ButtonStyled onClick={modalCtx.setIsVisible}>
					Связаться
				</ButtonStyled>
			</div>
		</SectionStyled>
	);
};

export { ContactInfo };
