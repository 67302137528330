import styled from 'styled-components';

export const InputElement = styled.div`
	width: 100%;
	margin-bottom: 25px;
	position: relative;

	.elements__item:last-child {
		margin-bottom: 0;
	}

	.item__input {
		padding: 15px 20px;
		border-radius: 15px;
		border: none;
		outline: none;
		width: 100%;
		@media (max-width: 375px) {
			padding: 15px 16px;
		}
	}

	.invalid {
		outline: 1px solid #ff7979;
	}

	.item__error {
		position: absolute;
		left: 50%;
		top: 125%;
		transform: translate(-50%, -50%);
		text-align: center;
		font-size: 14px;
		width: 100%;
		color: #ff7979;
	}

	.item__input::placeholder {
		color: #e4e4e4;
	}
`;
