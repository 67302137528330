import React from 'react';

import { ConsumablesItemProps } from './ConsumablesInterface';

export const ConsumablesItem = (props: ConsumablesItemProps) => {
	const { image, title, description } = props;
	return (
		<div className="list_item">
			<img className="item__image" src={image} alt="" />
			<div className="item__description">
				<h3 className="description__heading">{title}</h3>
				<p className="description_paragraph">{description}</p>
			</div>
		</div>
	);
};
