import React from 'react';

import AnchorLink from 'react-anchor-link-smooth-scroll';

import HeaderIcon from '../../Assets/SVG/HeaderIcon.svg';

import { HeaderItemProps } from './HeaderItemInterface';

const HeaderItem = (props: HeaderItemProps) => {
	const { title, href, offset } = props;

	return (
		<li className="navbar__list-item">
			<AnchorLink offset={offset} href={href} className="list-item__link">
				<span className="link__text">{title}</span>

				<img className="link__icon" src={HeaderIcon} alt="icon" />
			</AnchorLink>
		</li>
	);
};

export { HeaderItem };
