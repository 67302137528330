import React from 'react';
import { HeadingStyled } from '../../../StyledComponents/Heading';

import StabilizerImage from '../../../Assets/IMG/VastaStabilizer.jpg';
import { VacuumStabilizerSection } from './VacuumStabilizerStyled';

const VacuumStabilizer = () => {
	return (
		<VacuumStabilizerSection id="vacuum_stabilizer">
			<HeadingStyled>ваккумный стабилизатор васта</HeadingStyled>
			<div className="section__description">
				<div className="description__image">
					<img
						className="image"
						src={StabilizerImage}
						alt="vasta stabilizer"
					/>
				</div>
				<div className="description__details">
					<h3 className="details__heading">Характеристики:</h3>
					<div className="details__characteristics">
						<div className="characteristics__names">
							<ul className="names__list">
								<li className="list__item">
									<span className="item__name">
										Масса аппарата (без принадлежностей),
										кг:
									</span>
									<span className="item__characteristic">
										0,45
									</span>
								</li>
								<li className="list__item">
									Габаритные размеры аппарата без учета
									штуцера и клипсы (В×Ш×Г), мм:
									<span className="item__characteristic">
										189x92x44
									</span>
								</li>
								<li className="list__item">
									Максимальный вакуум, мм.рт.ст:
									<span className="item__characteristic">
										300
									</span>
								</li>
								<li className="list__item">
									Непрерывная работа:
									<span className="item__characteristic">
										до 7 дней
									</span>
								</li>
								<li className="list__item">
									Режим работы:
									<span className="item__characteristic">
										Постоянный, переменный
									</span>
								</li>
								<li className="list__item">
									Питание:
									<span className="item__characteristic">
										от сети и аккумуляторов (типа АА - 4
										шт.)
									</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</VacuumStabilizerSection>
	);
};

export { VacuumStabilizer };
