import styled from 'styled-components';

import image from '../../../Assets/IMG/Certificates.jpg';

export const TrustedParthnerStyled = styled.section`
	margin-top: 95px;

	.section__content {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
		column-gap: 20px;
		row-gap: 40px;
		margin-top: 65px;

		@media (max-width: 768px) {
			padding: 0 20px;
			grid-template-columns: 1fr;
		}
	}

	.item {
		background-color: #009d9b;
		color: #fff;
		padding: 30px;
		border-radius: 25px;
	}

	.item__heading {
		font-family: 'Intro Regular';
		font-weight: normal;
		font-size: 26px;
		padding-bottom: 20px;

		@media (max-width: 425px) {
			font-size: 24px;
		}

		@media (max-width: 320px) {
			font-size: 22px;
		}
	}

	.item__paragraph {
		padding-bottom: 3px;
		@media (max-width: 320px) {
			font-size: 14px;
		}
	}

	.content__item-1__block-1 {
		margin-bottom: 15px;
	}

	.item__list {
		list-style: disc;
		padding-left: 18px;
		max-width: 400px;
	}

	.list__item {
		padding-bottom: 2px;
		@media (max-width: 320px) {
			font-size: 14px;
		}
	}

	.content__item-3 {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		@media (max-width: 768px) {
			flex-direction: column;
			align-items: flex-start;
		}
	}

	.actions__btn {
		width: 150px;
		@media (max-width: 768px) {
			margin-top: 40px;
		}
	}

	.content__item-3 {
		background: #009d9b url(${image}) no-repeat 670px 20px;
	}

	.list__item:last-child {
		padding-bottom: 0;
	}

	.item:last-child {
		grid-column-start: 1;
		grid-column-end: 3;

		@media (max-width: 768px) {
			grid-column-start: auto;
			grid-column-end: auto;
		}
	}
`;
