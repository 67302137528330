import React from 'react';

import { InputProps } from '../FormInterface';
import { InputElement } from './InputStyled';

export const EmailInput = (props: InputProps) => {
	const { className, value, onBlur, onChange, hasError } = props;
	return (
		<InputElement className="elements__item">
			<input
				placeholder="E-mail"
				className={className}
				type="email"
				value={value}
				onBlur={onBlur}
				onChange={onChange}
				name="email"
			/>
			{hasError && value.length !== 0 && (
				<p className="item__error">
					Пожалуйста введите корректный адрес
				</p>
			)}
			{value.length === 0 && hasError && (
				<p className="item__error">Пожалуйста введите почтовый адрес</p>
			)}
		</InputElement>
	);
};
