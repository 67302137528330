import React from 'react';
import { ButtonWhite } from '../../../StyledComponents/ButtonWhite';
import { HeadingStyled } from '../../../StyledComponents/Heading';
import { TrustedParthnerStyled } from './TrustedParthnerStyled';

import RuVasta from '../../../Assets/Documents/RuVasta.pdf';

export const TrustedParthner = () => {
	return (
		<TrustedParthnerStyled>
			<HeadingStyled>Надежный партнер</HeadingStyled>
			<div className="section__content">
				<div className="content__item-1 item">
					<h2 className="item__heading">
						Возможные случаи применения медицинского стабилизатора
						вакуума ВаСта (Пересадка кожи или коже заменителей)
					</h2>
					<div className="content__item-1__block-1">
						<p className="item__paragraph">
							При пересадке кожи и коже заменителей на сложных
							областях:
						</p>
						<ul className="item__list">
							<li className="list__item">
								Области сгибания / разгибания
							</li>
						</ul>
					</div>
					<div className="content__item-1__block-2">
						<p className="item__paragraph">
							Сложные анатомические участки:
						</p>
						<ul className="item__list">
							<li className="list__item">Пах</li>
							<li className="list__item">Подмышечные впадины</li>
							<li className="list__item">Суставы</li>
						</ul>
					</div>
				</div>
				<div className="content__item-2 item">
					<h2 className="item__heading">
						Возможные случаи применения медицинского стабилизатора
						вакуума ВаСта (Пересадка кожи или коже заменителя
						амбулаторно)
					</h2>
					<ul className="item__list">
						<li className="list__item">
							Пациенты, которые нуждаются в ранней мобилизации
						</li>
						<li className="list__item">
							Пациенты, которые нуждаются в быстрой выписки из
							больницы
						</li>
					</ul>
				</div>
				<div className="content__item-3 item">
					<div className="content__item-3__content">
						<h2 className="item__heading">ВаСта обеспечивает</h2>
						<ul className="item__list">
							<li className="list__item">
								Возможность применения в амбулаторном режиме
							</li>
							<li className="list__item">
								Сокращение стационарного «койко-дня» в 3-7 раза
							</li>
							<li className="list__item">
								Заживляемость сложных ран в 2 раза быстрее
							</li>
							<li className="list__item">
								Снижение частоты перевязок
							</li>
							<li className="list__item">
								Предотвращение осложнений раны
							</li>
							<li className="list__item">
								Содействие быстрому сокращению объема раны
							</li>
							<li className="list__item">
								Улучшение качества жизни
							</li>
						</ul>
					</div>
					<div className="content__item-3__actions">
						<a href={RuVasta} download={RuVasta}>
							<ButtonWhite className="actions__btn">
								Документы
							</ButtonWhite>
						</a>
					</div>
				</div>
			</div>
		</TrustedParthnerStyled>
	);
};
