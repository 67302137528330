import React from 'react';

import { HeadingStyled } from '../../../StyledComponents/Heading';

import { AreasOfMedicineItem } from './AreasOfMedicineItem';

import { AreasOfMedicineSection } from './AreasOfMedicineStyled';

const AreasOfMedicine = () => {
	return (
		<AreasOfMedicineSection>
			<HeadingStyled>НАПРАВЛЕНИЯ МЕДИЦИНЫ</HeadingStyled>
			<div className="section__content">
				<AreasOfMedicineItem
					key="a1"
					title1="Военно-полевая хирургия"
					title2="Гнойная хирургия"
				/>
				<AreasOfMedicineItem
					key="a2"
					title1="Абдоминальная хирургия"
					title2="Торакальная хирургия"
				/>
				<AreasOfMedicineItem
					key="a3"
					title1="Медицина катастроф"
					title2="Комбустиология"
				/>
				<AreasOfMedicineItem
					key="a4"
					title1="Флебология"
					title2="Онкология"
				/>
			</div>
		</AreasOfMedicineSection>
	);
};

export { AreasOfMedicine };
