import React from 'react';

import downloadIcon from '../../Assets/SVG/DownloadIconFooter.svg';

import styled from 'styled-components';

interface ButtonProps {
	children: React.ReactNode;
}

const ButtonLB = styled.button`
	font-family: inherit;
	min-width: 250px;
	border: none;
	color: #fff;
	background: #38ebe9;
	font-size: 16px;
	padding: 7px 0;
	border-radius: 25px;
	align-items: center;
	display: flex;
	align-content: center;
	justify-content: center;
	cursor: pointer;

	@media (max-width: 320px) {
		transform: scale(0.9);
	}

	.icon {
		margin-right: 7px;
	}
`;

export const ButtonLightBlue = (props: ButtonProps) => {
	return (
		<ButtonLB>
			<img className="icon" src={downloadIcon} alt="" />
			{props.children}
		</ButtonLB>
	);
};
