import image1 from '../../../Assets/SVG/ConsumablesIcon1.svg';
import image2 from '../../../Assets/SVG/ConsumablesIcon2.svg';
import image3 from '../../../Assets/SVG/ConsumablesIcon3.svg';

export const ConsumablesData = [
	{
		id: 'c1',
		image: image1,
		title: 'Наборы для операций',
		description: `Губки 20-30 см из пенополиуретана, дренажные трубки с мягким портом и инцизные пленки. Наборы проходят стерилизацию оксидом этилена и поставляются в стерильных пакетах.`,
	},
	{
		id: 'c2',
		image: image2,
		title: 'Картридж / Мешок дренажный с крышкой и фильтром, одноразовый, Flovac, 1л',
		description: 'Возможность подключения к двум пациентам одновременно',
	},
	{
		id: 'c3',
		image: image3,
		title: 'Емкость накопительная многоразового применения, Flovac, 1л',
		description: 'Для жесткой фиксации одноразовых картриджей',
	},
];
