import styled from 'styled-components';

export const FooterStyled = styled.footer`
	padding: 60px 20px;
	margin-top: 130px;
	min-height: 310px;
	background: #009d9b;
	color: #fff;

	.wrapper {
		height: 100%;
	}

	.wrapper__content {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		height: 100%;
		@media (max-width: 1060px) {
			justify-content: center;
			flex-direction: column;
			align-items: center;
		}
	}

	.content__item {
		max-width: 300px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		@media (max-width: 1060px) {
			margin-bottom: 30px;
			text-align: center;
			width: 100%;
		}
	}

	.item__logo {
		margin-bottom: 10px;
	}

	.item__info {
		margin-bottom: 10px;
	}

	.content__item:last-child {
		margin-right: 0;
	}

	.privacy_policy {
		max-width: 250px;
		@media (max-width: 1060px) {
			max-width: 500px;
		}
	}

	.policy {
		margin-top: 30px;
		cursor: pointer;
	}

	.info__text {
		display: block;
		margin-bottom: 8px;
		font-family: 'Intro Bold Regular';
		@media (max-width: 1060px) {
			margin-bottom: 5px;
		}
	}

	.item__actions {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.actions__btn {
		margin-bottom: 20px;
		@media (max-width: 320px) {
			transform: scale(0.9);
		}
	}
`;
