import React from 'react';
import { Header } from './Components/Header/Header';
import { Footer } from './Components/Footer/Footer';
import { Main } from './Components/Main/Main';
import { ScrollToTopButton } from './Components/UI/ScrollToTopButton';
import { LeaveRequestModalContainer } from './Components/LeaveRequestModal/LeaveRequestModalContainer';

const App = () => {
	return (
		<>
			<LeaveRequestModalContainer />
			<Header />
			<Main />
			<Footer />
			<ScrollToTopButton />
		</>
	);
};

export default App;
