import React from 'react';
import { HeadingStyled } from '../../../StyledComponents/Heading';
import { WhyWeStyled } from './WhyWeStyled';

export const WhyWe = () => {
	return (
		<WhyWeStyled id="about_us">
			<HeadingStyled>почему выбирают нас?</HeadingStyled>
			<div className="section__content">
				<div className="content__achivments">
					<p className="achivments__paragraph">
						Научно производственное предприятие «Медика» основано в
						2009 году. За это время мы прошли большой путь: от идеи,
						до компании с богатым портфелем патентов и инновационных
						решений для медицинской отрасли.
					</p>
					<p className="achivments__paragraph">
						Патентный портфель НПП «Медика» насчитывает более 10
						изделий, большинство из которых в настоящий момент
						находятся в стадии завершения государственной
						регистрации и организации производства.
					</p>
				</div>
				<div className="content__facts">
					<ul className="facts__list">
						<li className="list__item">
							<p className="item__paragrapg--white">
								Экспертная команда врачей и разработчиков
							</p>
							<div className="item__line"></div>
							<p>Глубокие знания медицины и технологий</p>
						</li>
						<li className="list__item">
							<p className="item__paragrapg--white">
								Глубокая техническая экспертиза
							</p>
							<div className="item__line"></div>
							<p>Глубокие знания медицины и технологий</p>
						</li>
						<li className="list__item">
							<p className="item__paragrapg--white">
								Многолетний практический опыт
							</p>
							<div className="item__line"></div>
							<p>Решаем задачи быстро и качественно</p>
						</li>
						<li className="list__item">
							<p className="item__paragrapg--white">
								Ставим жизнь человека на первое место
							</p>
							<div className="item__line"></div>
							<p>
								Убеждены в том, что всегда есть способ улучшить
								качество жизни
							</p>
						</li>
					</ul>
				</div>
			</div>
		</WhyWeStyled>
	);
};
