import styled from 'styled-components';

export const DocumentationStyled = styled.section`
	margin-top: 90px;

	.actions__btn {
		width: 310px;
		padding: 10px;
		padding-left: 0;
		padding-right: 0;
		font-size: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		max-height: 50px;
		margin: 0 50px;
		@media (max-width: 850px) {
			margin: 0 20px;
		}
	}

	.btn__icon {
		padding-right: 10px;
	}

	.actions__line {
		border: 2px solid black;
		height: 55px;
		@media (max-width: 850px) {
			display: none;
		}
	}

	.actions__link {
		@media (max-width: 768px) {
			margin-bottom: 30px;
		}
		@media (max-width: 500px) {
			transform: scale(0.9);
		}
		@media (max-width: 320px) {
			transform: scale(0.75);
			margin-bottom: 15px;
		}
	}

	.section__actions {
		margin-top: 50px;
		display: flex;
		justify-content: center;
		align-items: center;

		@media (max-width: 768px) {
			flex-direction: column;
		}
	}
`;
