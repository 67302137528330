import React from 'react';
import { ResearchesStyled } from './ResearchesStyled';

export const Researches = () => {
	return (
		<ResearchesStyled id="researcher">
			<div className="section__description">
				<h2 className="description__heading">Исследования</h2>
				<p className="description__paragrapg">
					Вакуумный стабилизатор ВАСТА является российским
					инновационным продуктом, разработан с учетом пожеланий,
					потребностей и требований отечественной медицины – совместно
					с российским медицинским сообществом.
					<br />
					<br />В разработке медицинского оборудования принимают
					участие ведущие российские ученые, такие как: Владимир
					Николаевич Оболенский, к.м.н., заведующий хирургическим
					отделением № 3 ГБУЗ ГКБ № 13 г. Москвы, доцент кафедры общей
					хирургии лечебного факультета Российского национального
					исследовательского медицинского университета им. Н.И.
					Пирогова.
				</p>
			</div>
		</ResearchesStyled>
	);
};
