import styled from 'styled-components';

const HeadingStyled = styled.h2`
	text-align: center;
	color: #009d9b;
	border-bottom: 1px solid black;
	margin: 0 100px;
	padding-bottom: 5px;
	text-transform: uppercase;
	@media (max-width: 768px) {
		font-size: 22px;
	}
	@media (max-width: 425px) {
		font-size: 20px;
		margin: 0 20px;
	}
`;

export { HeadingStyled };
