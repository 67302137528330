import styled from 'styled-components';

export const FormStyled = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;

	.form__elements {
		display: flex;
		flex-direction: column;
		margin-top: 50px;
		width: 100%;
	}

	.form__btn {
		margin-top: 45px;
		/* width: 150px; */
		/* text-align: center; */
	}

	.btn-invalid {
		cursor: not-allowed;
		background: #bbbbbb;
	}
`;
