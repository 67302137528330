import React, { useState } from 'react';
import { GlobalStyle, HeaderAdaptiveStyled } from './HeaderAdaptiveStyled';

import { motion, AnimatePresence } from 'framer-motion';

import burgerMenu from '../../../Assets/SVG/BurgerIcon.svg';
import closeIcon from '../../../Assets/SVG/CloseIconAdaptive.svg';
import { HeaderAdaptiveItem } from './HeaderAdaptiveItem';

export const HeaderAdaptive = () => {
	const [toggleIcon, setToggleIcon] = useState(true);
	return (
		<HeaderAdaptiveStyled>
			<GlobalStyle toggleIcon={toggleIcon} key="global-body" />
			<AnimatePresence key="burger">
				<div className="burger-menu">
					{toggleIcon && (
						<motion.img
							initial={{ opacity: 0 }}
							animate={{ opacity: 1, rotate: 180 }}
							exit={{ opacity: 0 }}
							transition={{ duration: 0.2 }}
							onClick={() => setToggleIcon(false)}
							className="burger-menu__icon"
							src={burgerMenu}
						/>
					)}
					{!toggleIcon && (
						<motion.img
							initial={{ opacity: 0 }}
							animate={{ opacity: 1, rotate: 180 }}
							exit={{ opacity: 0 }}
							transition={{ duration: 0.2 }}
							onClick={() => setToggleIcon(true)}
							className="burger-menu__icon"
							src={closeIcon}
						/>
					)}
				</div>
			</AnimatePresence>
			<AnimatePresence key="on-close">
				{!toggleIcon && (
					<motion.nav
						initial={{ y: -1500 }}
						animate={{ y: 0 }}
						exit={{ y: -1500 }}
						transition={{ duration: 0.6, type: 'spring' }}
						className="navbar"
					>
						<ul className="navbar__list">
							<HeaderAdaptiveItem
								onClick={() => setToggleIcon(true)}
								title="ВАКУУМНЫЙ СТАБИЛИЗАТОР"
								href="#vacuum_stabilizer"
								offset={30}
							/>
							<HeaderAdaptiveItem
								onClick={() => setToggleIcon(true)}
								title="ДОКУМЕНТАЦИЯ"
								href="#documentation"
								offset={30}
							/>
							<HeaderAdaptiveItem
								onClick={() => setToggleIcon(true)}
								title="ИССЛЕДОВАНИЯ"
								href="#researcher"
								offset={30}
							/>
							<HeaderAdaptiveItem
								onClick={() => setToggleIcon(true)}
								title="О КОМПАНИИ"
								href="#about_us"
								offset={30}
							/>
							<HeaderAdaptiveItem
								onClick={() => setToggleIcon(true)}
								title="КОНТАКТЫ"
								href="#contacts"
								offset={0}
							/>
						</ul>
					</motion.nav>
				)}
			</AnimatePresence>
		</HeaderAdaptiveStyled>
	);
};
