import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

interface HeaderItemProps {
	title: string;
	href: string;
	offset: number;
	onClick: () => void;
}

export const HeaderAdaptiveItem = (props: HeaderItemProps) => {
	const { title, href, offset, onClick } = props;
	return (
		<li className="list__item">
			<AnchorLink
				onClick={onClick}
				offset={offset}
				href={href}
				className="item__link"
			>
				<span className="">{title}</span>
			</AnchorLink>
		</li>
	);
};
