import React from 'react';

import { InputProps } from '../FormInterface';
import { InputElement } from './InputStyled';

export const NameInput = (props: InputProps) => {
	const { onChange, value, className, onBlur, hasError } = props;
	return (
		<InputElement>
			<input
				onChange={onChange}
				value={value}
				placeholder="Ваше имя"
				type="text"
				className={className}
				onBlur={onBlur}
				name="name"
			/>

			{hasError && <p className="item__error">Пожалуйста введите имя</p>}
		</InputElement>
	);
};
