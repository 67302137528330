import React from 'react';

import RightArrowIcon from '../../../Assets/SVG/RightArrowIcon.svg';

import { AreasOfMedicineItemProps } from './AreasOfMedicineInterface';

const AreasOfMedicineItem = (props: AreasOfMedicineItemProps) => {
	const { title1, title2 } = props;

	return (
		<div className="content__list">
			<div className="list__block upper">
				<img className="block__icon" src={RightArrowIcon} alt="icon" />
				<span className="block__text">{title1}</span>
			</div>
			<div className="list__block">
				<img className="block__icon" src={RightArrowIcon} alt="icon" />
				<span className="block__text">{title2}</span>
			</div>
		</div>
	);
};

export { AreasOfMedicineItem };
