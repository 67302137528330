import React from 'react';

import closeIcon from '../../../Assets/SVG/CloseIcon.svg';

import { CallBackDiv } from './CallBackStyled';

export const CallBack = (props: { onClose: () => void }) => {
	return (
		<CallBackDiv>
			<h2 className="heading">
				Мы вам <br />
				перезвоним
			</h2>
			<button onClick={props.onClose} className="close-btn">
				<img className="close-btn__icon" src={closeIcon} alt="" />
			</button>
		</CallBackDiv>
	);
};
