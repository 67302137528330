import styled from 'styled-components';

export const VacuumTherapyStyled = styled.section`
	.section__content {
		display: flex;
		align-items: center;
		margin-top: 60px;
		justify-content: center;

		@media (max-width: 1100px) {
			flex-direction: column-reverse;
			align-items: center;
			padding: 0 30px;
		}
	}

	.content__description {
		margin-top: 20px;
		margin-right: 90px;
		text-align: justify;
		max-width: 550px;

		@media (max-width: 1100px) {
			margin-right: 0px;
		}
	}

	.content__image-block {
		max-width: 485px;
		@media (max-width: 1100px) {
			margin-bottom: 15px;
		}
	}

	.image-block__img {
		width: 100%;
	}

	.description__paragraph {
		font-size: 20px;
		line-height: 20px;

		@media (max-width: 768px) {
			text-align: center;
			font-size: 18px;
		}

		@media (max-width: 425px) {
			font-size: 17px;
		}
	}
`;
